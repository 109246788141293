.pdem_exhibition {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FAFAFA;

  &>.exhibition_header {
    display: flex;
    flex-direction: column;

    .exhibition_title {
      font-size: .64rem;
      font-weight: 500;
      color: #171717;
      line-height: .9067rem;
    }

    .exhibition_vice_title {
      margin-top: .2133rem;
      font-size: .3733rem;
      font-weight: 400;
      color: #666;
      line-height: .5867rem;
      text-align: justify;
    }
  }

  &>.exhibition_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: .2667rem;

    .exhibition_item {
      // width: 2.8533rem;
      // height: 6.1867rem;

      .exhibition_img {
        width: 100%;
        object-fit: contain;
        // height: 6.1867rem;
      }
    }
  }
}