.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5625rem;
  width: 100%;
  min-height: .41667rem;
  height: .41667rem;
  background-color: #ffffff;
  z-index: 999;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .1042rem;
    max-height: .41667rem;

    .logo_img {
      width: .82292rem;
      height: .3125rem;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;

    .menu_list {
      display: flex;
      justify-content: center;
      align-items: center;
    
      .menu_item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 .1042rem;
        min-width: 104px;
        height: .41667rem;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        line-height: 16px;
        position: relative;
        white-space: nowrap;
        cursor: pointer;
    
        &:after {
          background: #c82026;
          content: "";
          height: 2px;
          width: 0%; /* 初始宽度设置为0% */
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          -webkit-transition: all .3s ease;
          -o-transition: all .3s ease;
          transition: all .3s ease;
          visibility: visible;
          opacity: 1;
        }
    
        &:hover {
          color: #c82026;
          font-weight: 600;
          background-color: rgba(255, 255, 255, 0.1);
    
          &:after {
            width: 100%; /* 悬停时宽度变为100% */
          }
        }
    
        &.menu_active {
          font-weight: 600;
          color: #c82026;
          background-color: rgba(255, 255, 255, 0.1);
    
          &:after {
            width: 100%; /* 激活状态时宽度也为100% */
          }
        }
      }
    }

    .other {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 .1667rem 0 .0833rem;

      .oivider {
        width: 1px;
        height: 14px;
        background-color: #FFF;
      }

      .btn_search {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 .1667rem;
        cursor: pointer;

        .btn_search_img {
          min-width: .125rem;
          width: .125rem;
          height: .125rem;
        }
      }

      .login,
      .register {
        font-size: 16px;
        color: #FFF;
        line-height: 16px;
        white-space: nowrap;
        cursor: pointer;
      }
      .username {
        max-width: 64px; // 96px
        font-size: 16px;
        color: #FFF;
        line-height: 16px;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .search_box {
    position: absolute;
    right: .4167rem;
    bottom: .5208rem;
    display: flex;
    flex-direction: row;
    width: 1.875rem;
    height: .2604rem;
    background-color: #FAFAFA;
    box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.03);
    transition: bottom 300ms;
    opacity: 0.5;
    z-index: 999999;

    &.search_active {
      bottom: -0.2604rem;
      opacity: 1;
    }

    .search_input {
      flex: 1;
      height: .2604rem;

      &>input {
        margin: 0;
        padding: 0 .0625rem 0 .0833rem;
        width: 100%;
        height: 100%;
        font-size: .0729rem;
        font-weight: 400;
        color: #171717;
        border: none;
        background-color: transparent;
        outline: none;

        &::placeholder {
          font-size: .0729rem;
          font-weight: 400;
          color: #9C9C9C;
        }
      }
    }

    .btn_search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: .2604rem;
      height: .2604rem;
      background-color: #EAEAEA;
      cursor: pointer;

      .btn_search_img {
        width: .125rem;
        height: .125rem;
      }
    }
  }

  .user_box {
    position: absolute;
    // right: 0;
    bottom: .5208rem;
    display: flex;
    flex-direction: column;
    width: .5417rem;
    // height: .5417rem;
    height: .2708335rem;
    background-color: #FAFAFA;
    box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.03);
    transition: bottom 300ms;
    opacity: 0.5;
    z-index: 999999;

    &.user_active {
      bottom: -.2708335rem;
      opacity: 1;
    }

    .user_box_item {
      // flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: .2708335rem;
      font-size: .0729rem;
      font-weight: 400;
      color: #000;
      line-height: .1042rem;
      white-space: nowrap;

      &:hover {
        background-color: #EAEAEA;
      }
    }

    .user_box_divider {
      display: flex;
      padding: 0 .0521rem;
      width: 100%;

      .user_box_divider_box {
        width: 100%;
        height: .0052085rem;
        background-color: #EAEAEA;
      }
    }
  }
}

.header_ccupy {
  min-height: .41667rem;
  height: .41667rem;
}